.tab-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tab-container .ra-input {
    width: 49%;
    display: flex;
    justify-content: center;
}

/* .tab-container-show .ra-field {
    width: 49%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
} */
/* .tab-container-show .ra-field .MuiFormLabel-root {
    font-size: 30px;
} */
.tab-container-show .ra-field .MuiTypography-root {
    font-size: 20px !important;
    padding-left: 25px;
}

.tab-container .ra-input div {
    display: flex;
    justify-content: center;
}

.tab-container .ra-input .MuiFormControl-root {
    width: 98%;
}

.tab-container .ra-input.full {
    width: 100%;
}

.MuiFormControl-root {
    width: 100%;
}

.full {
    width: 100% !important;
}

.ra-input-scheduledActivitiesId div {
    width: 100%;
}

.tab-container .ra-input.ra-input-participants {
    width: 100%;
}

.tab-container .ra-input-code {
    width: 100%;
}

.no-label .ra-input-participants>.MuiFormControl-root>.MuiInputLabel-root {
    display: none;
}

.capitalize {
    display: block;
}

.capitalize:first-letter {
    text-transform: uppercase;
}

.new-line {
    width: 100% !important;
}

iframe {
    height: 85vh;
    width: 100%;
}

.ra-input-likes .MuiChip-deletable {
    height: 100%;
}

.ra-input-likes .RaAutocompleteArrayInput-chipContainerFilled-83 {
    margin: 13px 12px 10px 44px;
}

.show-page {
    height: 100%;
}

.show-page>div {
    height: 100%;
}

.show-page .MuiCardContent-root {
    height: 100%;
}

.show-page .MuiCardContent-root .ra-field {
    height: 100%;
}

.MuiChip-root {
    height: 21px !important;
}

.richText-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100% !important;
}

.richText-container .ra-rich-text-input {
    width: 48%;
}

.richText-container .ra-rich-text-input .MuiFormLabel-root {
    position: absolute;
    top: 0px;
    font-size: xx-large;
    color: black;
}

/* .lonelyRichText .ql-container {
    max-height: 6rem !important;
} */

.lonelyRichText .ql-editor {
    min-height: 5rem;
    height: 14rem;
}

.lonelyRichText .ra-rich-text-input .MuiFormLabel-root {
    position: absolute;
    top: 0px;
    font-size: xx-large;
    color: black;
}

/* .lonelyRichText .ra-rich-text-input {
    height: 8rem !important;
    max-height: 8rem !important;
} */

.ql-toolbar {
    display: flex;
    justify-content: flex-end;
}

.ql-toolbar .ql-formats:first-child {
    /* filter: opacity(0.5); */
}

.ql-editor {
    min-height: 300px;
    max-height: 300px;
}

.ql-stroke {
    stroke: rgb(151, 151, 151) !important;
}

.ql-fill {
    stroke: rgba(192, 192, 192) !important;
}

.simple-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100% !important;
}

.simple-container-bottom {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100% !important;
}

.simple-container .MuiFormControl-root {
    width: 45%;
}

.simple-container-bottom .MuiFormControl-root {
    width: 100%;
}

.simple-container-bottom>div {
    width: 45%;
    flex-grow: unset !important;
}

.full-padd {
    width: 90%;
}

.colum-inherit {
    width: initial !important;
}

.column-undefined {
    width: 10%;
}

.column-comment {
    width: 25%;
    max-width: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.column-technicalComment {
    width: 25%;
    max-width: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.column-commentForRelatives {
    width: 25%;
    max-width: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
}


.richText-container-show {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.richText-container-show .history-container {
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    margin: 10px;
    padding: 10px;
}

.richText-container-show .history-container .history-title {
    font-size: 24px;
    font-weight: 600;
}

.richText-container-show .history-container .history-body {
    min-height: 150px;
    max-height: 150px;
    max-width: 100%;
    padding: 10px;
    /* overflow-x: hidden; */
    overflow-y: auto;
}

.MuiDialog-paperWidthSm {
    max-width: 1000px !important;
}

.my-ra-delete-button {
    color: red !important;
}

.participant-accordion.MuiAccordionDetails-root {
    padding: 0px !important;
}

.participant-accordion.MuiAccordionDetails-root .MuiListItem-root {
    padding-left: 5px;
    padding-right: 5px;
}

.participant-accordion.MuiAccordionDetails-root .MuiListItemIcon-root {
    min-width: 35px;
}

.participant-accordion.MuiAccordionDetails-root .MuiListItemText-root .MuiTypography-root {
    font-size: 10px;
    overflow-y: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userTabs .MuiTab-root {
    font-size: 12px;
    font-weight: bold;
}

.userTabs .MuiTab-root {
    min-width: 120px;
}

.spin {
    animation: spin 0.8s linear infinite;
    /* Define la animación de giro */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#arrayCustomProfessional section {
    display: flex;
    justify-content: left;
}

#arrayCustomProfessional section > div.ra-input {
    display: flex;
    justify-content: left;
    width: 70vh;
    margin-left: 2rem;
}

#arrayCustomProfessional section > div.ra-input .MuiFormControl-root {
    width: 20rem;
}

#arrayCustomUser section {
    display: flex;
    align-items: center;
}

#arrayCustomUser section > div.ra-input .MuiFormControl-root {
    width: 21rem;
}

.container-contract {
    padding: 20px;
    margin: 20px auto;
    max-width: 450px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.message {
    font-size: 16px;
    color: #696969;
    margin-bottom: 12px;
}

.message-confirm1 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 12px;
}

.message-confirm2 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    text-align: center;
    margin-bottom: 12px;
}

.contract-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 10rem;
    /* Altura máxima del contenedor */
    overflow-y: auto;
    /* Habilita el scroll vertical */
    border-top: 1px solid #ddd;
    /* Opcional: Añadir un borde superior para separar */
    border-bottom: 1px solid #ddd;
    /* Opcional: Añadir un borde inferior */
}

.contract-item {
   background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 6px;
    font-size: 14px;
    color: #333; 

    
}

.contract-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.contract-header, .contract-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.contract-header {
    background-color: #f0f0f0;
    font-weight: bold;
}

.item-name, .header-name {
    flex: 3;  /* Ajusta según la necesidad para el nombre del contrato */
}

.item-status, .header-status {
    flex: 2;  /* Ajusta según la necesidad para el estado */
    display: flex;
    align-items: center;
    justify-content: center;
}

.description-table{
    color: #696969;
    font-size: 12px;
}

.app {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
}


.calendar-activity:not(tr) {
    background-color: #003488 !important;
}

.calendar-register:not(tr) {
    background-color: #00ab8e !important;
}


.react-calendar__tile--active:not(.react-calendar__tile--now) {
    background-color: transparent !important;
    color: inherit !important;
    border: none !important;
}

.react-calendar__tile--now {
    color: #757575 !important;
    background-color: #dce9ff !important;
}

.event-day {
    position: relative;
}

.event-day::after {
    content: attr(data-date);
    background-color: #00348866;
    /* Ajusta el último valor para la transparencia (0.0 a 1.0) */
    color: rgb(0, 0, 0);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.react-calendar__month-view__days__day {
    padding: 3px !important;
}

.react-calendar {
    font-family: "FS Matthew" !important;
}

.rbc-time-view,
.rbc-month-view,
.react-calendar {
    border-radius: 8px;
    border-color: #003488 !important;
    overflow: hidden !important;
}

.rbc-agenda-view {
    border-radius: 8px;
    border-color: #003488 !important;
}

.rbc-time-header{
    margin-right: 14px;
}

.rbc-label.rbc-time-header-gutter {
    width: 47.7516px;
    min-width: 47.7516px;
    max-width: 47.7516px;
}

/* DASHBOARD */

.dashboardContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.dashboardHeaderContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #00ab8e;
    height: 8rem;
    border-radius: 1rem;
    padding: 2.5%;
}

.dashboardBodyContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
}

.sessionsGridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    gap: 3rem;
  }

  .gridBoxBody{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 1rem;
    overflow-y: auto;
    max-height: 16rem;
  }

.typographyProfile {
    color: white;
}

.cardProfile {
    border-radius: 2rem !important;
    position: absolute;
    bottom: -15%;
    width: -webkit-fill-available;
    margin-right: 2.5%;
}

.avatarProfile {
    width: 85px !important;
    height: 85px !important;
}

.customSubheader {
    font-size: 15px !important;
}

.divCustom {
    max-height: 16rem;
    display: flex;
    flex-direction: column;
    padding: 2% 4%;
    margin: 2rem;
    border-radius: 1rem;
    background-color: rgb(240, 240, 240);
    padding: 1rem;
}

.divCalendar {
    display: flex;
    flex-direction: column;
    max-height: 16rem;
    padding: 2% 4%;
    margin: 2rem;
    border-radius: 1rem;
    background-color: rgb(240, 240, 240);
    padding: 1rem;
}

.divCalendar>span {
    align-self: flex-start;
}

.divCalendar>div {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.agenda-typograhy{
    cursor: pointer; 
}

/* Estilos HOME -> Actividades */

.grid-activity {
    background-color: rgb(240, 240, 240);
    padding: 2% 2%;
    margin: 0 2rem;
    border-radius: 1rem;
    display: flex;
}

.grid-card-activity-left{
    margin-top: 2rem !important;
    width: 70% !important;
    overflow-y: auto;
    max-height: 41rem;
    margin-left: -2rem !important; 
}

.grid-card-activity-right{
    place-content: center;
    height: fit-content;
    margin-top: 2rem !important;
    width: 30% !important;
}
.div-img{
    text-align: -webkit-center;
}
.card {
    border: none; 
    border-radius: 0;
    transition: transform 0.3s;
    background-color: rgb(240, 240, 240) !important;
    box-shadow: none !important;
}

.card:hover {
    transform: translateY(-5px);
}

.card-media {
    width: 95% !important;
    border-radius: 2rem;
    height: 9rem;
    /* object-fit: fill !important; */
    object-position: center !important;
}

.card-content {
    padding: 16px;
}

.card-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.card-actions > button{
    font-size: 0.66rem;
}


.font-zise-rem{
    font-size: 0.66rem!important;
}

.grid-new-activity{
    padding: 3% !important;
    text-align: center;
}

.new-activity {
    cursor: pointer; 
    color: #a4a4a4 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 2px double #ccc;
}

.new-activity span {
    font-size: 3rem;
    border: 0;
}

.new-activity button {
    font-size: 24px;
    
}
.contract-Typograhy{
    padding-left: 1.5rem;
}

.card-actions-activity{
    display: flex;
    justify-content: space-between;
    padding: 5px 1rem !important;
}
.description-typograhy{
    color: #a1a0a0 !important;
}

.span-finally-activity{
    color: #a4a4a4;    
    width: 100%;
    text-align-last: center;
}
.spanTitle{
    width: 70%;
    font-size: 0.88rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    height: fit-content;
    text-overflow: ellipsis;
}

.inputAutoCompleteParticipant-style {
    position: relative;
    top: -1.7rem;
    width: -webkit-fill-available;
    text-align-last: left;
    border: 1px solid #ccc;
    max-height: 180px;
    overflow-y: auto;
    background: white;
    z-index: 1000;
}


.inputAutoComplete-style {
    position: absolute;
    top: 3.5rem;
    left: 5px;
    width: -webkit-fill-available;
    justify-content: left !important;
    border: 1px solid #ccc;
    max-height: 180px;
    overflow-y: auto;
    background: white;
    z-index: 1000;
}


.MuiCard-root.MuiPaper-root {
    position: relative; /* Para que los elementos absolutos dentro de este contenedor se posicionen correctamente */
    overflow: visible !important; /* Permite que el contenido sobresalga */
}

.typography-csv-assigned{
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    font-size: 0.75rem;
}

.divide-x > * + * {
    border-right-width: 0px;
    border-left-width: 1px;
    border-left-color: black;
    border-left-style: solid;
}

.dashboard-usuario * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dashboard-user-image {
    height: 5.5rem;
}

.dashboard-user-image img {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 10px;
    object-fit: cover;
}

.MuiListItemIcon-root, .MuiListItem-root > .MuiSvgIcon-root {
    color: #00ab8e !important;
}

.custom-activity-delete{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-end: 5px;
    padding: 10px;
    border-Radius: 4px;
}